import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Anonymous from "../components/anonymousbat/AnonymousBat";

const AnonymousBat = () => (
  <Layout mode={true} sticky_footer={false}>
    <SEO title="Amber by inFeedo | Anonymous Bat" />
    <Anonymous/>
  </Layout>
)

export default AnonymousBat